<template>
  <div class="jobs p-8">
    <div class="mx-auto max-w-7xl space-y-6">
      <div class="flex justify-between">
        <div class="flex items-center">
          <h1 class="text-3xl font-semibold ">
            Jobs
          </h1>
          <router-link
            v-if="!error"
            to="/jobs/mapview"
            class="hover-trigger gap-1 text-blue-600 hover:text-blue-700 flex items-center ml-4"
          >
            <span class="pt-1 font-medium">Map View</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7"
              />
            </svg>
          </router-link>
        </div>

        <div class="flex gap-4">
          <div class="min-w-48 flex flex-col">
            <label
              for="crew_filter"
              class="block text-sm font-medium text-gray-600"
            >
              Crew
            </label>
            <select
              v-model="filters.crew"
              name="crew_filter"
              class="border rounded py-2 border-gray-200 text-lg"
            >
              <option> </option>
              <option v-for="crew in crewArray" :key="crew.id" :value="crew.id">
                {{ crew.crew_name }}
              </option>
            </select>
          </div>
          <div class="min-w-48 flex flex-col">
            <label
              for="date_filter"
              class="block text-sm font-medium text-gray-600"
              >Date</label
            >
            <select
              v-model="filters.date"
              class="border rounded py-2 border-gray-200 text-lg"
            >
              <option> </option>
              <option v-for="date in dateArray" :key="date" :value="date">
                {{ dateFromISO(date) }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="shadow overflow-hidden border border-gray-200">
        <table class="w-full divide-y divide-gray-200" id="job-table">
          <thead class="bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 text-left tracking-wider">
                <div class="flex gap-1">
                  <span
                    class="text-xs font-medium text-gray-500 uppercase"
                    @click="
                      sortBy == 'sequence'
                        ? (sortAsc = !sortAsc)
                        : (sortBy = 'sequence')
                    "
                    >Number</span
                  >
                  <span class="p-0.5 text-black">
                    <svg
                      :class="{
                        'hidden sequence': sortBy !== 'sequence',
                        'hidden asc': !sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      :class="{
                        'hidden sequence': sortBy !== 'sequence',
                        'hidden desc': sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 11l7-7 7 7M5 19l7-7 7 7"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left tracking-wider">
                <div class="flex gap-1">
                  <span
                    class="text-xs font-medium text-gray-500 uppercase"
                    @click="
                      sortBy == 'accepted'
                        ? (sortAsc = !sortAsc)
                        : (sortBy = 'accepted')
                    "
                    >Date Accepted</span
                  >
                  <span class="p-0.5 text-black">
                    <svg
                      :class="{
                        'hidden accepted': sortBy !== 'accepted',
                        'hidden asc': !sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      :class="{
                        'hidden accepted': sortBy !== 'accepted',
                        'hidden desc': sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 11l7-7 7 7M5 19l7-7 7 7"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left tracking-wider">
                <div class="flex gap-1">
                  <span
                    class="text-xs font-medium text-gray-500 uppercase"
                    @click="
                      sortBy == 'scheduled'
                        ? (sortAsc = !sortAsc)
                        : (sortBy = 'scheduled')
                    "
                    >Date Scheduled</span
                  >
                  <span class="p-0.5 text-black">
                    <svg
                      :class="{
                        'hidden scheduled': sortBy !== 'scheduled',
                        'hidden asc': !sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      :class="{
                        'hidden scheduled': sortBy !== 'scheduled',
                        'hidden desc': sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 11l7-7 7 7M5 19l7-7 7 7"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 text-left tracking-wider">
                <div class="flex gap-1">
                  <span
                    class="text-xs font-medium text-gray-500 uppercase"
                    @click="
                      sortBy == 'crew'
                        ? (sortAsc = !sortAsc)
                        : (sortBy = 'crew')
                    "
                    >Crew</span
                  >
                  <span class="p-0.5 text-black">
                    <svg
                      :class="{
                        'hidden crew': sortBy !== 'crew',
                        'hidden asc': !sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                      />
                    </svg>
                    <svg
                      :class="{
                        'hidden crew': sortBy !== 'crew',
                        'hidden desc': sortAsc,
                      }"
                      class="h-3 w-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M5 11l7-7 7 7M5 19l7-7 7 7"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th scope="col" class="px-6 py-3 tracking-wider text-center">
                <span class="text-xs font-medium text-gray-500 uppercase">
                  Completed
                </span>
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Customer
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Location
              </th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="job in filteredJobs"
              :key="job.id"
              @click="goTo(`/jobs/${job.id}`)"
              class="hover:bg-gray-50"
            >
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ job.sequence_number }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ dateFromSeconds(job.createdAt?.seconds) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ dateFromISO(job.job_scheduled) }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ job.crew?.crew_name }}
              </td>
              <td
                class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
              >
                {{ job.job_completed ? "&check;" : "" }}
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div>{{ job.customer?.billing_business }}</div>
                <div>{{ job.customer?.customer_name }}</div>
              </td>
              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {{ job.location?.location_address1 }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import useLuxon from "@/composables/useLuxon";

export default {
  props: ["user"],
  setup(props) {
    const isLoading = ref(true);
    const router = useRouter();
    const sortBy = ref("sequence");
    const sortAsc = ref(true);
    const crewArray = ref([]);
    const dateArray = ref([]);
    const filters = ref({
      date: "",
      crew: "",
    });

    const business_id = props.user.claims.business_id ?? props.id;

    const { error, documents: jobs } = getCollection(
      `businesses/${business_id}/jobs`
    );

    const { error: crews_err, documents: crews } = getCollection(
      `businesses/${business_id}/crews`
    );

    const filteredJobs = computed(() => {
      return jobs.value?.filter((job) => {
        if (filters.value.date && job.job_scheduled != filters.value.date) {
          return;
        }
        if (filters.value.crew && job.crew.id != filters.value.crew) {
          return;
        }
        return true;
      });
    });

    watch([jobs, crews], () => {
      if (jobs.value && crews.value) {
        const crewSet = new Set();
        const dateSet = new Set();
        jobs.value.forEach((job) => {
          if (job?.crew.id.length > 1) {
            crewSet.add(job.crew.id ?? null);
          }
          if (job?.job_scheduled.length > 1) {
            dateSet.add(job.job_scheduled ?? null);
          }
        });
        crewSet.forEach((crew_id) => {
          crewArray.value.push({
            id: crew_id,
            crew_name: crews.value?.find(({ id }) => id === crew_id).crew_name,
          });
        });
        crewArray.value.sort((a, b) => (a.crew_name > b.crew_name ? 1 : -1));
        dateArray.value = Array.from(dateSet).sort();
        isLoading.value = false;
      }
    });

    watchEffect(() => {
      jobs.value?.sort((a, b) => {
        if (sortBy.value == "sequence" && sortAsc.value)
          return a.sequence_number - b.sequence_number;
        if (sortBy.value == "sequence" && !sortAsc.value)
          return b.sequence_number - a.sequence_number;
        if (sortBy.value == "accepted" && sortAsc.value)
          return a.createdAt - b.createdAt;
        if (sortBy.value == "accepted" && !sortAsc.value)
          return b.createdAt - a.createdAt;
        if (sortBy.value == "scheduled" && sortAsc.value)
          return a.job_scheduled > b.job_scheduled ? 1 : -1;
        if (sortBy.value == "scheduled" && !sortAsc.value)
          return b.job_scheduled > a.job_scheduled ? 1 : -1;
        if (sortBy.value == "crew" && sortAsc.value)
          return a.crew?.crew_name > b.crew?.crew_name ? 1 : -1;
        if (sortBy.value == "crew" && !sortAsc.value)
          return b.crew?.crew_name > a.crew?.crew_name ? 1 : -1;
      });
    });

    const goTo = (url) => {
      router.push(url);
    };

    const { dateFromISO, dateFromSeconds } = useLuxon();

    return {
      isLoading,
      sortBy,
      sortAsc,
      error,
      jobs,
      crews_err,
      crews,
      goTo,
      dateArray,
      crewArray,
      dateFromISO,
      dateFromSeconds,
      filters,
      filteredJobs,
    };
  },
};
</script>
